<template>
  <v-container fluid class="fill-height text-center">
    <v-card class="fill-height container--fluid" width="100vw" flat outlined>
      <v-data-table
        :headers="headers"
        :items="users"
        :items-per-page="-1"
        :search="searchUser"
        :loading="loadingData"
        item-key="id"
        no-data-text="No hay datos para mostrar..."
        loading-text="Cargando los datos, por favor espere!"
        class="elevation-1"
        :footer-props="{
          showFirstLastPage: false,
          disableItemsPerPage: false,
          itemsPerPageAllText: 'Todas',
          itemsPerPageText: 'Filas por página:',
        }"
      >
        <template v-slot:top>
          <v-toolbar flat class="align-center">
            <v-toolbar-title class="text-subtitle-1 text-md-h5"
              >Gesti&oacute;n de Usuarios</v-toolbar-title
            >
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-responsive
              max-width="300"
              height="45"
              class="hidden-sm-and-down"
            >
              <v-text-field
                id="findtext-user"
                label="Buscar.."
                name="finduser"
                outlined
                dense
                single-line
                clearable
                background-color="white"
                hide-details
                v-model="searchUser"
                class="white--text mt-0 pt-0 mr-2"
                prepend-inner-icon="mdi-magnify"
              ></v-text-field>
            </v-responsive>
            <v-dialog
              :fullscreen="$vuetify.breakpoint.smAndDown"
              v-model="dialog"
              max-width="550px"
              scrollable
              persistent
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :small="$vuetify.breakpoint.smAndDown"
                  color="blue darken-1"
                  dark
                  depressed
                  elevation="0"
                  class="mb-2"
                  v-bind="attrs"
                  v-on="on"
                  @click="newItem"
                >
                  Adicionar
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="text-h6 grey lighten-2">
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                  <v-container>
                    <v-row class="mt-2">
                      <v-col cols="8">
                        <v-text-field v-model="editedItem.name" label="Nombre">
                        </v-text-field>
                        <v-text-field
                          v-model="editedItem.last_name"
                          label="Apellidos"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col class="pl-2 ml-2">
                        <v-row justify="center">
                          <v-img
                            v-if="imagePreview != null"
                            :src="imagePreview"
                            :lazy-src="require('@/assets/images/no-image.jpg')"
                            max-height="100"
                            max-width="100"
                            aspect-ratio="1"
                            class="white--text align-center justify-center elevation-2"
                          >
                          </v-img>
                        </v-row>
                        <v-row>
                          <v-file-input
                            v-model="imageFile"
                            accept="image/png, image/jpeg, image/bmp"
                            show-size
                            clearable
                            label="Cargar/Cambiar imágen"
                            placeholder="Archivo de imágen"
                            prepend-icon="mdi-camera"
                            @change="changePreviewImage()"
                          >
                          </v-file-input>
                        </v-row>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-model="editedItem.email"
                          :rules="emailRules"
                          label="E-mail"
                          required
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-model="editedItem.password"
                          :counter="10"
                          :rules="passwordRules"
                          label="Password"
                          required
                          dense
                          :append-icon="
                            showPassword ? 'mdi-eye' : 'mdi-eye-off'
                          "
                          :type="showPassword ? 'text' : 'password'"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-select
                          v-model="editedItem.roles"
                          :items="roles"
                          dense
                          label="Rol de usuario"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-checkbox
                          v-model="editedItem.active"
                          label="Usuario Activo"
                          dense
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="close"> Cancelar </v-btn>
                  <v-btn color="primary" text @click="save"> Guardar </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h6 headline"
                  >Esta seguro que desea eliminar este elemento?
                </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete"
                    >Cancelar</v-btn
                  >
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                    >Proceder</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.active="{ item }">
          <v-simple-checkbox v-model="item.active" disabled></v-simple-checkbox>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                small
                class="mr-2"
                @click="editItem(item)"
              >
                mdi-pencil
              </v-icon>
            </template>
            <span>Editar</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                small
                class="mr-2"
                @click="deleteItem(item)"
              >
                mdi-delete
              </v-icon>
            </template>
            <span>Eliminar</span>
          </v-tooltip>
        </template>
        <template v-slot:no-data>
          <span class="text-h5">No hay registros en el listado!</span>
          <br />
          <v-btn color="primary" @click="getUsers"> Recargar </v-btn>
        </template>
      </v-data-table>
      <v-snackbar v-model="snackBar" :timeout="2000">
        {{ snackText }}
        <template v-slot:action="{ attrs }">
          <v-btn v-bind="attrs" icon @click="snackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-card>
  </v-container>
</template>

<script>
import userService from "@/providers/UsersService";
import { changeInformationSEO } from "@/providers/KeywordsServices";

export default {
  name: "AdminUserComponent",
  data: () => ({
    snackBar: false,
    snackText: "",
    showPassword: false,
    loadingData: false,
    searchUser: "",
    dialog: false,
    dialogDelete: false,
    imageFile: null,
    imagePreview: null,
    selectedImageIndex: -1,
    emailRules: [
      (v) => !!v || "El Correo Electrónico es obligatorio",
      (v) => /.+@.+\..+/.test(v) || "El Correo Electrónico debe ser válido",
    ],
    passwordRules: [
      (v) => !!v || "La contraseña es obligatoria",
      (v) =>
        (v && v.length >= 8) ||
        "La contraseña debe tener al menos de 8 caracteres",
    ],
    imageRules: [
      (value) =>
        !value ||
        value.size < 2000000 ||
        "El tamaño de la imagen no debe de sobrepasar los 2 MB!",
    ],
    users: [],
    roles: ["ADMIN", "BUSINESS", "USER"],
    headers: [
      { text: "Id", value: "id", align: " d-none", width: 0 }, // para ocultar la columna Id.
      {
        text: "Nombre",
        align: "start",
        sortable: true,
        value: "name",
      },
      { text: "Apellidos", value: "last_name" },
      { text: "E-mail", value: "email" },
      { text: "Activo", value: "active", align: "center", width: 100 },
      { text: "Acciones", value: "actions", width: 100, sortable: false },
    ],
    editedIndex: -1,
    editedItem: {
      id: 0,
      name: "",
      last_name: "",
      email: "",
      password: "",
      roles: "",
      active: false,
      pictures: [],
    },
    defaultItem: {
      id: 0,
      name: "",
      last_name: "",
      email: "",
      password: "",
      roles: "",
      active: false,
      pictures: [],
    },
    /*
               cruds: [
                   {text: 'Adicionar', icon: 'mdi-account-plus', action: ''},
                   {text: 'Modificar', icon: 'mdi-account-edit', action: ''},
                   {text: 'Delete', icon: 'mdi-account-remove', action: ''},
                   {text: 'Renovar Contraseña', icon: 'mdi-account-key', action: ''},
               ], */
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo usuario" : "Editar usuario";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  methods: {
    getUsers() {
      // carga los datos desde el proveedor de datos.
      this.loadingData = true;
      userService.getAllRecords().then((record) => {
        // console.log('record: ', record.value);
        this.users = record.value;
        // this.users = record.data;
        // console.log("Usuarios: ", this.users);
        this.loadingData = false;
      });
    },
    newItem() {
      this.imagePreview = null;
      this.imageFile = null;
      this.selectedImageIndex = -1;
    },
    editItem(item) {
      this.editedIndex = this.users.indexOf(item);
      // console.log('editedIndex: ', this.editedIndex);
      this.editedItem = Object.assign({}, item);
      // console.log('editedItem', this.editedItem);
      if (this.editedItem.pictures.length > 0) {
        this.imagePreview = this.editedItem.pictures[0].url;
        this.selectedImageIndex = this.editedItem.pictures[0].id;
      } else {
        this.imagePreview = null;
        this.selectedImageIndex = -1;
      }
      // console.log('Preview imagen: ', this.imagePreview, '\n');
      this.imageFile = null;
      // console.log('Data imagen: ', this.imageFile, '\n');
      this.dialog = true;
    },
    deleteItem(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      // console.log('Item to delete: %s %s ', this.editedItem.id, this.editedItem);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.users.splice(this.editedIndex, 1);
      userService.deleteRecord(this.editedItem.id);
      // console.log('Item deleted:', this.editedItem);
      this.closeDelete();
      this.snackText = "Registro eliminado con éxito.";
      this.snackBar = true;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.imagePreview = null;
        this.selectedImageIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      if (this.editedIndex > -1) {
        // Actualizar el registro por el metodo PUT
        Object.assign(this.users[this.editedIndex], this.editedItem);
        userService.updateRecord(
          this.editedItem,
          this.selectedImageIndex,
          this.imageFile
        );
        //.updateRecord(this.editedItem.id, this.editedItem);
      } else {
        // Agrega el registro por el metodo POST
        this.users.push(this.editedItem);
        userService.addRecord(this.editedItem, -1, this.imageFile);
        //addRecord(this.editedItem);
      }
      this.close();
      this.snackText = "Datos del formulario guardado con éxito...";
      this.snackBar = true;
      this.getUsers();
    },
    changePreviewImage() {
      if (this.imageFile) {
        // console.log('Data imagen: ', this.imageFile, '\n');
        let reader = new FileReader();
        reader.onload = () => {
          this.imagePreview = reader.result;
          // console.log('Preview imagen: ',this.imagePreview, '\n');
        };
        reader.readAsDataURL(this.imageFile);
        if (this.editedItem.pictures.length === 0) {
          let imagePicture = {
            id: 0,
            module_id: 1,
            url: this.imageFile.name,
          };
          this.editedItem.pictures.push(imagePicture);
          // console.log('Picture actualizado: ', this.editedItem);
        }
        this.snackText = "Imágen de perfil cargada con éxito...";
        this.snackBar = true;
      }
    },
  },
  mounted() {
    this.users = [];
    this.getUsers();
    changeInformationSEO();
  },
};
</script>

<style scoped>
.v-btn {
  text-transform: none !important;
}
</style>
